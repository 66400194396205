class VideoLandingPageWidget implements LandingPageWidget{
    getClassName(): string {
        return "video";
    }

    getName(): string {
        return "Video";
    }

    loadData(data: object): void {
    }

    render(container: HTMLDivElement, rowIndex: number, firstLoad: boolean, prefixClassName: string): void {
    }

}